import React from "react";

export default ({ heading, data }) => {
	let Cell = heading ? 'td' : 'th'
	return (
		<table>
			<tbody>
				{heading && <tr key='head' className="thead-BG">
					<th colSpan="5">{heading}</th>
				</tr>}
				<tr key={heading+'0'+data.headers[0].data}>
					<Cell>#</Cell>
					{data.headers.map(data => (
						<Cell key={heading+data}>
							<span>{data}</span>
						</Cell>
					))}
				</tr>
				{data.detail.map((data, index) => (
					<tr  key={heading+index+'detail'+Math.random()+Math.random()}>
						<td>{index + 1}</td>
						{Object.values(data).map(v => <td key={heading+index+'td'+Math.random()+Math.random()}>{v}</td>)}
					</tr>
				))}
			</tbody>
		</table>
	);
};
