import React, { Component } from "react";
import FrontFooter from "../FrontStatic/common/Front-Footer";
import FrontWrapper from "../FrontStatic/common/FrontWrapper";
import { GradingData } from "./GradingJson";
import LinearTable from "./SubComponents/LinearTable";
import SimpleTable from "./SubComponents/SimpleTable";

class Grading extends Component {
    render() {
        let clarity = GradingData.CLARITY.data;
        let color = GradingData.COLOR.colorName;
        let cut = GradingData.CUT;
        let symmetry = GradingData.SYMMETRY;
        let blackInclusion = GradingData.BLACK_INCLUSION_BASE;
        let flouresence = GradingData.FLOURESENCE;
        let milky = GradingData.MILKY;
        let heartArrow = GradingData.HEART_ARROW_BASE;
        let lab = GradingData.LAB;
        let polish = GradingData.POLISH;
        let whiteInclusion = GradingData.WHITE_INCLUSION_BASE;
        let openBase = GradingData.OPEN_BASE;
        let colorshade = GradingData.COLOR_SHADE;
        let eyeClean = GradingData.EYE_CLEAN;
        let roughOrigin = GradingData.ROUGH_ORIGIN;
        let newArrivalDate = GradingData.NEW_ARRIVALS_DATE;

        return (
            <FrontWrapper location={this.props.location}>
                <div className="scroll-content-wrapper" data-scrollbar>
                    <div className="page-main page-current">
                        <div
                            className="page-toload legacy-page"
                            data-bodyclassname="legacy"
                        >
                            <main className="page-content" role="main">
                                <section className='section-container our-story our-service-bn head-grading' style={{ padding: "30px 0 0 0" }}>
                                    <div style={{ margin: "auto" }} className='head-grading '>
                                        <h2 className='show-text head padd-max js-type-animation-alt'>HK Grading</h2>
                                    </div>
                                    <div className='diamond-detail-content grading-main-block'>
                                        <div className='width-full grading-content'>
                                            <div className='diamond-education static-page'>
                                                <div className='d-flex d-tv-block d-xs-block'>
                                                    <div className='width-33 width-tv-full grading-table-block pr-tv-0 pr-30 mb-50 mb-xs-20'>
                                                        <div className='card-table  grading-table '>
                                                            <div className='card-table-body'>
                                                                <table>
                                                                    <tbody>
                                                                        <tr className='thead-BG'>
                                                                            <th>CARAT</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Size Range 0.30 up</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='width-33 width-tv-full grading-table-block pr-tv-0 pr-30 mb-50 mb-xs-20'>
                                                        <div className='card-table grading-table'>
                                                            <div className='card-table-body'>
                                                                <LinearTable data={clarity} heading='CLARITY' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='width-33 width-tv-full grading-table-block mb-50 mb-xs-20'>
                                                        <div className='card-table grading-table'>
                                                            <div className='card-table-body'>
                                                                <LinearTable data={color} heading='COLOR' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex d-xs-block d-tv-block'>
                                                    <div className='width-full'>
                                                        <div className='d-flex d-xs-block d-tv-block'>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable data={cut} heading='CUT' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0  width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable data={polish} heading='POLISH' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 vmb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={symmetry}
                                                                            heading='SYMMETRY'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex d-xs-block d-tv-block'>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0  width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={whiteInclusion}
                                                                            heading='WHITE INCLUSION BASE'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={blackInclusion}
                                                                            heading='BLACK INCLUSION BASE'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={openBase}
                                                                            heading='OPEN BASE'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex d-xs-block d-tv-block'>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={flouresence}
                                                                            heading='FLUORESCENCE'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={colorshade}
                                                                            heading='COLOR SHADE'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable data={milky} heading='MILKY' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex d-xs-block d-tv-block'>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={eyeClean}
                                                                            heading='EYE CLEAN'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={heartArrow}
                                                                            heading='HEART & ARROW BASE'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable
                                                                            data={roughOrigin}
                                                                            heading='ROUGH ORIGIN'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex d-xs-block d-tv-block'>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table grading-block mb-50 mb-xs-20'>
                                                                    <div className='card-table-body'>
                                                                        <SimpleTable data={lab} heading='LAB' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='width-33 width-tv-full pr-30 pr-tv-0 width-xs-full'>
                                                                <div className='card-table grading-table mb-50'>
                                                                    <div className='card-table-body'>
                                                                        <table>
                                                                            <tbody>
                                                                                <tr className='thead-BG'>
                                                                                    <th colSpan='1'>
                                                                                        NEW ARRIVALS DATE
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{newArrivalDate.dataNew}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mb-50 text-center grading-btn front-btn'>
                                                    <a
                                                        type='button'
                                                        href={"HK-Grading.pdf"}
                                                        className='button primary-fill'
                                                        download
                                                    >
                                                        Download HK Grading
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <FrontFooter />
                            </main>
                        </div>
                    </div>
                    {/*<SEO url={this.props.location.pathname.split("/")[1]} />*/}
                </div>
            </FrontWrapper>
        );
    }
}

export default Grading;
