export const GradingData = {
    CLARITY: {
        data: ["FL", "IF", "VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "SI3", "I1", "I2", "I3"],
    },
    COLOR: {
        colorName: [
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O-P",
            "Q-R",
            "W-X",
            "Y-Z",
        ],
    },
    CUT: {
        headers: ["Cut Grades", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "EX",
                desc: "Excellent",
            },
            {
                code: "VG",
                desc: "Very Good",
            },
            {
                code: "GD",
                desc: "Good",
            },
            {
                code: "F",
                desc: "Fair",
            },
        ],
    },
    POLISH: {
        headers: ["Polish", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "EX",
                desc: "Excellent",
            },
            {
                code: "VG",
                desc: "Very Good",
            },
            {
                code: "GD",
                desc: "Good",
            },
            {
                code: "F",
                desc: "Fair",
            },
        ],
    },
    SYMMETRY: {
        headers: ["Symmetry Grades", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "EX",
                desc: "Excellent",
            },
            {
                code: "VG",
                desc: "Very Good",
            },
            {
                code: "GD",
                desc: "Good",
            },
            {
                code: "F",
                desc: "Fair",
            },
        ],
    },

    WHITE_INCLUSION_BASE: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(WT0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "WT1",
                desc: "Minor",
            },
            {
                code: "WT2",
                desc: "Medium",
            },
            {
                code: "WT3",
                desc: "Major",
            },
        ],
    },

    BLACK_INCLUSION_BASE: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(BT0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "BT1",
                desc: "Minor",
            },
            {
                code: "BT2",
                desc: "Medium",
            },
            {
                code: "BT3",
                desc: "Major",
            },
        ],
    },

    OPEN_BASE: {
        headers: ["Grade", "Inc. Type", "Location", "Description"],
        headerKeys: ["code", "type", "location", "size"],
        detail: [
            {
                code: "N(WC0 /WT0)",
                type: "Open",
                location: "---",
                size: "None",
            },
            {
                code: "NV",
                type: "Open",
                location: "---",
                size: "None Visible",
            },
            {
                code: "CO1",
                type: "Open",
                location: "Crown",
                size: "Minor",
            },
            {
                code: "TO1",
                type: "Open",
                location: "Table",
                size: "Minor",
            },
            {
                code: "CO2",
                type: "Open",
                location: "Crown",
                size: "Medium",
            },
            {
                code: "TO2",
                type: "Open",
                location: "Table",
                size: "Medium",
            },
            {
                code: "CO3",
                type: "Open",
                location: "Crown",
                size: "Major",
            },
            {
                code: "TO3",
                type: "Open",
                location: "Crown",
                size: "Major",
            },
        ],
    },

    FLOURESENCE: {
        headers: ["FL Grades", "Parameters"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N",
                desc: "None Fluorescence",
            },
            {
                code: "FNT",
                desc: "Faint Fluorescence",
            },
            {
                code: "MED",
                desc: "Medium Fluorescence",
            },
            {
                code: "STG",
                desc: "Strong Fluorescence",
            },
            {
                code: "VST",
                desc: "Very Strong Fluorescence",
            },
        ],
    },
    COLOR_SHADE: {
        headers: ["Grade", "Color Shade", "Color Shade Description"],
        headerKeys: ["code", "detail", "desc"],
        detail: [
            {
                code: "N",
                detail: "WH/OF WH/YL",
                desc: "-",
            },
            {
                code: "NV",
                detail: "Brown/Grey/Pink",
                desc: "None Visible",
            },
            {
                code: "BRN1",
                detail: "Brown",
                desc: "Faint",
            },
            {
                code: "BRN2",
                detail: "Brown",
                desc: "Light",
            },
            {
                code: "BRN3",
                detail: "Brown",
                desc: "Dark",
            },
        ],
    },
    LAB: {
        headers: ["Lab", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "GIA",
                desc: "Gemological Institute of America",
            },
            {
                code: "IGI",
                desc: "International Gemological Institute",
            },
            {
                code: "HRD",
                desc: "Hoge Raad voor Diamant",
            },
            {
                code: "EGL",
                desc: "European Gemological Laboratory",
            },
            {
                code: "IIDGR",
                desc: "International Institute of Diamond Grading and Research",
            },
            {
                code: "AGS",
                desc: "American Gem Society",
            },
            {
                code: "FAITH",
                desc: "Hari Krishna Export's Certificate",
            },
            {
                code: "FM",
                desc: "Forever Mark",
            },
            {
                code: "FMI",
                desc: "Forever Mark Inscribed",
            },
            {
                code: "FMG",
                desc: "Forever Mark Grading",
            },
            {
                code: "FMG/I",
                desc: "Forever Mark Grading / Inscribed",
            },
        ],
    },

    MILKY: {
        headers: ["Grade", "Size/Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(M0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "M1",
                desc: "Minor",
            },
            {
                code: "M2",
                desc: "Medium",
            },
            {
                code: "M3",
                desc: "Major",
            },
        ],
    },
    EYE_CLEAN: {
        headers: ["Grade", "%", "Description"],
        headerKeys: ["code", "perc", "desc"],
        detail: [
            {
                code: "EC1",
                perc: "75% - 100%",
                desc: "Sure",
            },
            {
                code: "EC2",
                perc: "50% - 74%",
                desc: "Medium",
            },
            {
                code: "EC3",
                perc: "25% - 49%",
                desc: "Medium",
            },
            {
                code: "V",
                perc: "0% - 25%",
                desc: "Inclusion Visible",
            },
        ],
    },
    HEART_ARROW_BASE: {
        headers: ["Grade", "%", "Description"],
        headerKeys: ["code", "perc", "desc"],
        detail: [
            {
                code: "EX",
                perc: "75% - 100%",
                desc: "Ex H&A",
            },
            {
                code: "VG",
                perc: "50% - 74%",
                desc: "VG H&A",
            },
            {
                code: "NV",
                perc: "25% - 49%",
                desc: "None Visible",
            },
            {
                code: "N",
                perc: "0% - 25%",
                desc: "None",
            },
        ],
    },
    ROUGH_ORIGIN: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "CM",
                desc: "Canada Mark",
            },
            {
                code: "CME",
                desc: "Canada Mark Eligible",
            },
            {
                code: "FM",
                desc: "Forever Mark",
            },
            {
                code: "FMG",
                desc: "Forever Mark Grading",
            },
            {
                code: "FME",
                desc: "Forever Mark",
            },
            {
                code: "FMI",
                desc: "Forever Mark Inscribed",
            },
        ],
    },
    NEW_ARRIVALS_DATE: {
        dataNew: ["This is the date on which new goods transfer for sale."],
    },
    BLACK_CROWN: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(BC0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "BC1",
                desc: "Minor",
            },
            {
                code: "BC2",
                desc: "Medium",
            },
            {
                code: "BC3",
                desc: "Major",
            },
        ],
    },
    OPEN_TABLE: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(TO0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "TO1",
                desc: "Minor",
            },
            {
                code: "TO2",
                desc: "Medium",
            },
            {
                code: "TO3",
                desc: "Major",
            },
        ],
    },
    OPEN_PAVILION: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(PO0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "PO1",
                desc: "Minor",
            },
            {
                code: "PO2",
                desc: "Medium",
            },
            {
                code: "PO3",
                desc: "Major",
            },
        ],
    },
    WHITE_CROWN: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(WC0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "WC1",
                desc: "Minor",
            },
            {
                code: "WC2",
                desc: "Medium",
            },
            {
                code: "WC3",
                desc: "Major",
            },
        ],
    },
    OPEN_CROWN: {
        headers: ["Grade", "Description"],
        headerKeys: ["code", "desc"],
        detail: [
            {
                code: "N(CO0)",
                desc: "None",
            },
            {
                code: "NV",
                desc: "None Visible",
            },
            {
                code: "CO1",
                desc: "Minor",
            },
            {
                code: "CO2",
                desc: "Medium",
            },
            {
                code: "CO3",
                desc: "Major",
            },
        ],
    },
};
