import React from "react";
import Grading from "../components/Grading/Grading";
import SEO from "../components/SEO";
import {graphql} from "gatsby";

const HKGrading = (props) => <React.Fragment>
    <SEO {...(props?.data?.seo?.frontmatter || {})}/><Grading {...props}/></React.Fragment>;
export default HKGrading;
export const query = graphql`
  query HKGradingQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "hk-grading-seo"}}) {
        frontmatter {
            title
            description
        }
    }
 }
`;

