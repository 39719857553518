import React from "react";

export default ({ heading, data }) => {
    let res = ''
    data.map(d => (
        res += d + ", "
    ))
    res = res.slice(0,-2)
    return (
        <table>
            <tbody>
                {heading && (
                    <tr className='thead-BG'>
                        <th>{heading}</th>
                    </tr>
                )}
                <tr>
                    <td>
                        {res}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
